import { useState } from 'react';
import './cardDeclineBtn.scss';

interface CardDeclineBtnProps {
   handleClick: () => void;
}

const CardDeclineBtn = ({ handleClick }: CardDeclineBtnProps ) => {
    return (
        <button
            type="button"
            onClick={async (e) => {
                e.preventDefault();

                if (handleClick) {
                    handleClick();
                }
            }}
            className="card-decline-button"
        >
            Decline Service
        </button>
    );
};

export default CardDeclineBtn;
