import axios from 'axios';
import { useState, FC, useEffect } from "react";
import { useLoadScript} from '@react-google-maps/api';

import "./cardGoogleAddress.scss";


type CardGoogleAddressProps = {
  address?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const CardGoogleAddress: FC<CardGoogleAddressProps> = ({ address, style, className }) => {
  const [zip, setZip] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY || '',
  });

  const text = [address, city, state, zip].filter(Boolean).join(', ');

  useEffect(() => {
    if (!isLoaded || !address) return;

    const fetchCoordinates = async (address: string) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`
        );

        if (response.data.results.length > 0) {
          const addressComponents = response.data.results[0].address_components;

          addressComponents.forEach((component: any) => {
            if (component.types.includes('postal_code')) setZip(component.long_name);
            if (component.types.includes('administrative_area_level_1')) setState(component.long_name);
            if (component.types.includes('locality')) setCity(component.long_name);
          });

          setLink(
            `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(text)}`
          );
        } else {
          console.error('No results found');
        }
      } catch (error) {
        console.error('Error fetching coordinates', error);
      }
    };

    fetchCoordinates(address);
  }, [isLoaded, address]);

  if (!isLoaded) return null;

  return (
    <div className="address-content">
      <div className="address-content-column">
        <p>Address</p>
      </div>
      <div className="address-content-column">
        <a href={link || '#'} target="_blank" rel="noreferrer">
          {text}
        </a>
      </div>
    </div>
  );
};
  