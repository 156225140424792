import { useState } from "react";
import { useApolloClient } from '@apollo/client';
import { Col } from "reactstrap";
import CartLayout from "./CardLayout";
import CardDetails from "../StagedCard/CardDetails/CardDetails";
import flagIcon from "../../assets/img/icons/cards/flag-icon.svg";
import copyIcon from "../../assets/img/icons/cards/copy-icon.svg";
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import { TicketActions } from "../Modal/TicketActions";
import { TicketsPreviewModal } from "../Modal/TicketsPreviewModal";

import "./card.scss";


const Cart = ({
  cartSchemaConfig,
  cartData,
  dataKey,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  cardsWithTickets,
  ticketsForCard,
  packageDetailsId,
}: any) => {
  const client = useApolloClient();

  const [file, setFile] = useState<string | undefined>("");
  const [ticket, setTicket] = useState<any | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");

  const tickets = ticketsForCard?.filter((ticket: any) => ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink);

  if (
    dataKey === "MLB Card" ||
    dataKey === "NBA Card" ||
    dataKey === "NHL Card" ||
    dataKey === "Multi-day Gift Card"
  ) {
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }

              if (field.name.toLowerCase() === 'elink') {
                return (
                  <>
                    <a
                    className=""
                    href={item['eLink']}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#007bff" }}
                    >
                      {item['eLink']}
                    </a>

                    <div className="divider"></div>
                  </>
                );
                
              }

              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                return (
                  <>
                    <CardDetails
                      cardIcon={copyIcon}
                      cardTitle={item[field.name]}
                      titleStyle={{ fontSize: '16px', fontWeight: '400', fontFamily: "'Inter', sans-serif", color: '#5F6778' }}
                    />

                    <div className="divider"></div>
                  </>
                )
              }
              
              if (field.name === "notes" && item.hasOwnProperty(field.name)) {
                return (
                  <div className="mt-2" style={{ width: '100%'}}>
                    <CardDetails
                      cardIcon={flagIcon}
                      cardTitle="Additional Notes"
                      cardDescription={item[field.name]}
                    />

                    <div className="divider"></div>
                  </div>
                ) 
              }

              if (item.hasOwnProperty(field.name)) {
                return (
                  <>
                    <Col
                      xs={!isMobile ? "6" : "12"}
                      style={{
                        ...field.style,
                        paddingLeft: "2rem",
                      }}
                      className="d-flex"
                      key={idx}
                    >
                      <span className="">
                        {field.label}: {item[field.name]}
                      </span>
                    </Col>

                    <div className="divider"></div>
                  </>
                );
              }
              
              return null;
            });
          })}

          {!!tickets.length && cartData?.cartDetails?.isPublished &&
            <div style={{ width: "100%" }}>
              
              <CardDetails
                cardIcon={ticketIcon}
                cardTitle="Access Tickets now"
                children={
                  <div className="mt-4">
                    {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                        <TicketActions
                          key={index}
                          ticket={ticket}
                          setFile={setFile}
                          setIsModalOpened={setIsModalOpened}
                          setTicket={setTicket}
                        />
                      ))
                    ) : (
                      <p>No tickets available</p>
                    )}
                  </div>
                }
              />

              <TicketsPreviewModal
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                file={file}
                ticket={ticket}
              />

              <div className="divider"></div>
            </div>
          }
      </div>
    );
  }
  
  if (
    dataKey === "Fanatics Credit"
  ) {
    const isLink =  parsedCardDetails?.some((item: any) => 'eLink' in item);
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.filter((item: any) => {
            if (isLink && 'eGiftCode' in item) {
              return false;
            }
            return true;
          }).map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }

              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                const description = item?.[field.name as any]?.split("${or}");
                return (
                  <>
                    <CardDetails
                      cardIcon={copyIcon}
                      titleStyle={{ fontSize: '16px', fontWeight: '400', fontFamily: "'Inter', sans-serif", color: '#5F6778' }}
                      cardTitle={isLink ? description[1] : description[0]}
                      // cardDescription={item[field.name]}
                    />

                    <div className="divider"></div>
                  </>
                );
              }
              
              if (field.name === "notes" && item.hasOwnProperty(field.name) && item[field.name]) {
                return (
                  <div className="mt-2" style={{ width: '100%'}}>
                    <CardDetails
                      cardIcon={flagIcon}
                      cardTitle="Additional Notes"
                      cardDescription={item[field.name]}
                    />

                    <div className="divider"></div>
                  </div>
                )
              }

              if (item.hasOwnProperty(field.name)) {
                return (
                  <>
                    <Col
                      xs={!isMobile ? (isLink ? "12" : "6") : "12"}
                      style={{
                        ...field.style,
                        paddingLeft: "2rem",
                      }}
                      className="d-flex"
                      key={idx}
                    >
                      <span className="e-field">
                        {field.label}: {isLink ? <a href={item[field.name]} target="_blank" rel="noreferrer">{item['eLink']}</a> : item[field.name]}
                      </span>
                    </Col>

                    <div className="divider"></div>
                  </>
                );
              }
              
              return null;
            });
          })}

          {!!tickets.length && cartData?.cartDetails?.isPublished &&
            <div style={{ width: "100%" }}>
              <CardDetails
                cardIcon={ticketIcon}
                cardTitle="Access Tickets now"
                children={
                  <div className="mt-4">
                    {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                        <TicketActions
                          key={index}
                          ticket={ticket}
                          setFile={setFile}
                          setIsModalOpened={setIsModalOpened}
                          setTicket={setTicket}
                        />
                      ))
                    ) : (
                      <p>No tickets available</p>
                    )}
                  </div>
                }
              />
              <TicketsPreviewModal
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                file={file}
                ticket={ticket}
              />

              <div className="divider"></div>
            </div>
          }
      </div>
    );
  }

  return (
    <div key={cartInfoId}>
      {cartSchemaConfig?.map((schemaConfig: any, index: number, arr: any) => {
        const isCardDetailsOpened =
          schemaConfig.cartInfo &&
          cartData.cartDetails &&
          isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

        const isLearnMoreOpened =
          schemaConfig.learnMore &&
          isLearnMoreOpenedId.includes(cartData.cartDetails.packageDetailsId);

        if (schemaConfig.skipInCart) {
          return;
        }

        if (
          schemaConfig.hideIfEmpty &&
          !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
        ) {
          return;
        }

        if (
          schemaConfig.hideIfFieldWithNameHasValue &&
          parsedCardDetails?.[schemaConfig.hideIfFieldWithNameHasValue]
        ) {
          return;
        }

        if (isCardDetailsOpened) {
          return (
            <>
              {Object.entries(schemaConfig)?.map(
                ([cartDetailsKey, cartDetailsConfig]: any, idx) => {
                  if (cartDetailsKey === "cartInfo" || cartDetailsKey === "checkPending") return;
                  return (
                    <>
                      <CartLayout
                        schemaConfig={cartDetailsConfig}
                        cartData={cartData}
                        isCartInfoOpenedId={isCartInfoOpenedId}
                        handleOpenDetails={handleOpenDetails}
                        parsedCardDetails={parsedCardDetails}
                        cartInfoId={index}
                        key={idx}
                        cardsWithTickets={cardsWithTickets}
                        ticketsForCard={ticketsForCard}
                        tickets={tickets}
                        packageDetailsId={packageDetailsId}
                        isPublished={cartData?.cartDetails.isPublished}
                      />
                    </>
                  );
                },
              )}
              {cartData?.cartDetails.isPublished && <div className="divider"></div>}
            </>
          );
        }

        if (isLearnMoreOpened) {
          return (
            <>
              <CartLayout
                schemaConfig={schemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={parsedCardDetails}
                cartInfoId={index}
                handleOpenLearnMore={handleOpenLearnMore}
                isLearnMoreOpenedId={isLearnMoreOpenedId}
                isLearnMoreOpened={isLearnMoreOpened}
                key={index}
                cardsWithTickets={cardsWithTickets}
                ticketsForCard={ticketsForCard}
                tickets={tickets}
                packageDetailsId={packageDetailsId}
                isPublished={cartData?.cartDetails.isPublished}
              />

              {cartData?.cartDetails.isPublished && <div className="divider"></div>}      
            </>
          );
        }

        return (
          <>
            <CartLayout
              schemaConfig={schemaConfig}
              cartData={cartData}
              isCartInfoOpenedId={isCartInfoOpenedId}
              handleOpenDetails={handleOpenDetails}
              parsedCardDetails={parsedCardDetails}
              cartInfoId={index}
              handleOpenLearnMore={handleOpenLearnMore}
              isLearnMoreOpenedId={isLearnMoreOpenedId}
              key={index}
              cardsWithTickets={cardsWithTickets}
              ticketsForCard={ticketsForCard}
              tickets={tickets}
              packageDetailsId={packageDetailsId}
              isPublished={cartData?.cartDetails.isPublished}
            />

            {cartData?.cartDetails.isPublished && <div className="divider"></div>}
          </>
        );
      })}

      {!!tickets.length && cartData?.cartDetails?.isPublished &&
        <div style={{ width: "100%" }}>
          <CardDetails
            cardIcon={ticketIcon}
            cardTitle="Access Tickets now"
            children={
              <div className="mt-4">
                {ticketsForCard && ticketsForCard.length ? (
                  ticketsForCard.map((ticket: any, index: number) => (
                    <TicketActions
                      key={index}
                      ticket={ticket}
                      setFile={setFile}
                      setIsModalOpened={setIsModalOpened}
                      setTicket={setTicket}
                    />
                  ))
                ) : (
                  <p>No tickets available</p>
                )}
              </div>
            }
          />
          <TicketsPreviewModal
            isModalOpened={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            file={file}
            ticket={ticket}
          />

          <div className="divider"></div>  
        </div>
      }
    </div>
  );
};

export default Cart;
