import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import CryptoJS from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import moment from "moment";
import TicketsModal from "../../components/Modal/TicketsModal";
import {
  GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
  GET_FULL_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
  GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
} from "../../queries/vip-workflow";
import { CRYPTO_JS_SECRET } from "../../utils/constants";
import CartsContainer from "./CartsContainer";
import PackageMenu from "./PackageMenu";
import Timer from "./Timer";
import { IPackageOrderWithDetails } from "../../utils/interfaces";
import Smartlook from "smartlook-client";
import { getUserId } from "../../utils/services";
import { useHistory } from "react-router-dom";
import PackageInfoCartFilters from "./PackageInfoCartFilters";
import InformationalStickyBlock from "./InformationalStickyBlock";

import "./packageInfo.scss";

const PackageInfoPage = ({
  match: {
    params: { invoiceSecret },
  },
}: any) => {
  const client = useApolloClient();
  const history = useHistory();

  const slashesReturnedToEncryptedInvoiceId = invoiceSecret.replace(
    /HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61/g,
    "/",
  );

  const decryptedInvoiceId = Number.parseFloat(
    CryptoJS.decrypt(slashesReturnedToEncryptedInvoiceId.toString(), CRYPTO_JS_SECRET).toString(
      enc,
    ),
  );

  const [airportTransferType, setAirportTransferType] = useState<"transfer1" | "transfer2">(
    "transfer1",
  );
  
  const [vegasLimoTransferType, setVegasLimoTransferType] = useState<"transfer1" | "transfer2">(
    "transfer1",
  );

  const [packageDetailsId, setPackageDetailsId] = useState<number | null>(null);
  const [isEditDetailsModalOpened, setIsisEditDetailsModalOpened] = useState(false);
  const [isTicketsModalOPened, setIsTicketsModalOpened] = useState(false);

  const [loading, setLoading] = useState(false);
  
  // const isPublished = cart?.cartDetails?.isPublished;

  const [modalType, setModalType] = useState("");
  const [activeCardsFilter, setActiveCardsFilter] = useState("");
  const [packageOrderWithDetails, setPackageOrderWithDetails] =
    useState<IPackageOrderWithDetails | null>(null);
  const [isCartInfoOpenedId, setIsCartInfoOpenedId] = useState<number[]>([]);
  const [isLearnMoreOpenedId, setIsLearnMoreOpenedId] = useState<number[]>([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [missingInfoCards, setMissingInfoCards] = useState([]);
  const [packageData, setPackageData] = useState<any>({});
  const [isInvoiceArchived, setIsInvoiceArchived] = useState(false);
  const [fullPackageData, setFullPackageData] = useState<any>({});

  const [showStickyBlock, setShowStickyBlock] = useState(false);
  
  const ticketDescription = packageOrderWithDetails?.orderDetails.find(
    (cart: any) => cart.group === 'Ticket',
  )?.description || '';
  const getPackageData = async () => {
    setLoading(true);

    const { data } = await client.query({
      query: GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
      variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: activeCardsFilter },
      fetchPolicy: "no-cache",
    });

    const { data: fullDataPackages } = await client.query({
      query: GET_FULL_PACKAGE_WITH_DETAILS_BY_INVOICE_ID,
      variables: { invoiceId: decryptedInvoiceId, orderDetailsFilter: activeCardsFilter },
      fetchPolicy: "no-cache",
    });

    const filtersData = await client.query({
      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
      variables: { invoiceId: decryptedInvoiceId },
      fetchPolicy: "cache-first",
    });

    const isInvoiceArchived =
      moment(data?.getOnePackageWithDetailsForClient?.eventDate, "MMMM Do YYYY, h:mm a") < moment();

    setFullPackageData(fullDataPackages?.getOneFullPackageWithDetails);
    setPackageOrderWithDetails(data?.getOnePackageWithDetailsForClient);
    setAvailableFilters(filtersData.data?.getAvailableFiltersForPackageInfo.filtersList);

    setPackageData(data);
    setIsInvoiceArchived(isInvoiceArchived);

    setMissingInfoCards(filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor);
    setLoading(false);
  };

  useEffect(() => {
    if (Number.isNaN(decryptedInvoiceId)) {
      history.push("/not-found");
    } else {
      getPackageData();
    }
  }, [activeCardsFilter]);

  useEffect(() => {
    Smartlook.init("357cdd7d112927200da07d414da3ca51050e52d7", { region: "us", cookies: false });
    Smartlook.record({ numbers: true, forms: true });
    Smartlook.identify(getUserId(), {
      invoiceId: decryptedInvoiceId,
    });
    Smartlook.track("packageInfo", { invoiceId: decryptedInvoiceId });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setShowStickyBlock(scrollPosition > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenDetails = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isCartInfoOpenedId.includes(packageDetailsId)) {
      setIsCartInfoOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsCartInfoOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };

  const handleOpenLearnMore = (e: any, packageDetailsId: number) => {
    e.preventDefault();
    if (isLearnMoreOpenedId.includes(packageDetailsId)) {
      setIsLearnMoreOpenedId((prev) => {
        return prev.filter((el) => el !== packageDetailsId);
      });
    } else {
      setIsLearnMoreOpenedId((prev) => {
        return [...prev, packageDetailsId];
      });
    }
  };

  return (
    <div className="main">
      <Timer />
      <TicketsModal
        isModalOpened={isTicketsModalOPened}
        setIsModalOpened={setIsTicketsModalOpened}
        isShowEventTicketsAdditionalMessage={
          (packageOrderWithDetails?.isHasTicketsExceptEvent ||
            packageOrderWithDetails?.isHasIdInUrlField
            ) &&
          !(
            packageOrderWithDetails?.isHasAcceptanceUrl ||
            packageOrderWithDetails?.isHasPdfEventTicket
          )
        }
        packageOrderWithDetails={packageOrderWithDetails}
        invoiceId={decryptedInvoiceId}
        ticketDescription={ticketDescription}
        fullPackageData={fullPackageData}
      />
      <Container fluid>
        <Row>
          <Col md="6" xl="4" xxl="3" className="d-flex justify-content-center p-0">
            {showStickyBlock && (
              <InformationalStickyBlock 
                packageOrder={packageData?.getOnePackageWithDetailsForClient}
                setIsModalOpened={setIsTicketsModalOpened}
              />
            )}
            <PackageMenu
              activeCardsFilter={activeCardsFilter}
              setActiveCardsFilter={setActiveCardsFilter}
              availableFilters={availableFilters}
              packageOrder={packageData?.getOnePackageWithDetailsForClient}
              missingInfoCards={missingInfoCards}
              setIsModalOpened={setIsTicketsModalOpened}
            />
          </Col>
          <Col md="6" xl="8" xxl="9" className="pt-4 cards-container" id="carts-container">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="info" size="lg" />
              </div>
            ) : packageOrderWithDetails ? (
              <>
                {/* <PackageDisclaimer orderDetails={packageOrderWithDetails?.orderDetails} /> */}
                <div className="scrollable-cards-container">
                <PackageInfoCartFilters
                  activeCardsFilter={activeCardsFilter}
                  setActiveCardsFilter={setActiveCardsFilter}
                  availableFilters={availableFilters}
                  missingInfoCards={missingInfoCards}
                />
                <CartsContainer
                  packageOrderWithDetails={packageOrderWithDetails}
                  setModalType={setModalType}
                  setPackageDetailsId={setPackageDetailsId}
                  setIsModalOpened={setIsisEditDetailsModalOpened}
                  airportTransferType={airportTransferType}
                  setAirportTransferType={setAirportTransferType}
                  vegasLimoTransferType={vegasLimoTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  handleOpenLearnMore={handleOpenLearnMore}
                  isLearnMoreOpenedId={isLearnMoreOpenedId}
                  isInvoiceArchived={isInvoiceArchived}
                  setPackageOrderWithDetails={setPackageOrderWithDetails}
                  decryptedInvoiceId={decryptedInvoiceId}
                  missingInfoCards={missingInfoCards}
                  setMissingInfoCards={setMissingInfoCards}
                  // isPublished={isPublished}
                />
                </div>
              </>
            ) : (
              <h1>Items Not Found</h1>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PackageInfoPage;
