import { useApolloClient } from "@apollo/client";
import { GET_FILE_BASE64, GET_FILE_DOWNLOAD_LINK } from "../../queries/vip-workflow";
import { base64ToBlob } from "../../utils/services";
import downloadIcon from "../../assets/img/icons/cards/download-icon.svg";
import qrCodeIcon from "../../assets/img/icons/cards/qr-code-icon.svg";
import linkloadIcon from "../../assets/img/icons/cards/link-icon.svg";
import { successMsg } from "../../cache/vars";

export const TicketActions = ({
  ticket,
  setFile,
  setTicket,
  setIsModalOpened,
}: any) => {
  const client = useApolloClient();

  const handleDownloadClick = async () => {
    const newWindow = window.open('', '_blank');
    
    const { data } = await client.query({
      query: GET_FILE_DOWNLOAD_LINK,
      variables: { id: ticket.id },
      fetchPolicy: "no-cache",
    });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any)?.MSStream;
    if (isIOS) {
      try {
        const { data: base64Data } = await client.query({
          query: GET_FILE_BASE64,
          variables: { id: ticket.id },
          fetchPolicy: "no-cache",
        });

        if (base64Data?.getFile) {
          const matches = base64Data.getFile.match(/^data:(.*);base64,/);
          const mimeType = matches ? matches[1] : 'application/octet-stream';
          const base64Content = base64Data.getFile.split(',')[1];
          const blob = base64ToBlob(base64Content, mimeType);
          const blobUrl = URL.createObjectURL(blob);

          if (newWindow) {
            newWindow.location.href = blobUrl;
          }

          setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
        } else {
          if (newWindow) {
            newWindow.close();
          }
        }
      } catch (error) {
        console.error("Error fetching or processing the file:", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    } else {
      if (data?.getFileDownloadLink) {
        const link = document.createElement("a");
        link.href = data?.getFileDownloadLink;
        link.download = ticket.key;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn("No download link available.");
        if (newWindow) {
          newWindow.close();
        }
      }
    }
  };

  const handlePreviewClick = async () => {
    const { data } = await client.query({
      query: GET_FILE_BASE64,
      variables: { id: ticket.id },
      fetchPolicy: "no-cache",
    });
    
    setFile(data?.getFile);
    setTicket(ticket);
    setIsModalOpened(true);
  };
  
  if (!ticket?.key && !ticket?.eTicketLink) return null;

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center" style={{ width: "100%" }}>
      {ticket?.key && 
        <>
          <button
            type="button"
            style={{ cursor: "pointer" }}
            className="btn btn-link p-0"
            onClick={handleDownloadClick}
          >
            {ticket.isQrCode ? (
              <img src={qrCodeIcon} alt="qr-icon" style={{ cursor: "pointer" }} />
            ) : (
              <img src={downloadIcon} alt="download-icon" style={{ cursor: "pointer" }} />
            )}
          </button>
          <button
              type="button"
              className="btn btn-link p-2"
              style={{
                overflowWrap: "anywhere",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={handlePreviewClick}
            >
              {ticket.isQrCode ? "Open QR Code" : "View Tickets"}
          </button>
        </>
      }
      {ticket?.eTicketLink && 
        <>
          <button 
          type="button"
          style={{ cursor: 'pointer' }}
          className="btn btn-link p-0"
          onClick={(e) => {
            e.preventDefault();

            navigator.clipboard.writeText(ticket.eTicketLink);
            successMsg('Copied to clickboard');
          }}
        >
          {ticket.isQrCode 
            ? <img src={qrCodeIcon} alt="qr-code-icon" style={{ cursor: 'pointer' }}/>
            : <img src={linkloadIcon} alt="link-icon" style={{ cursor: 'pointer' }}/>
          }
          </button>

          <a className="btn btn-link pl-0 text-start" href={ticket.eTicketLink} target="_blank" rel="noreferrer" 
            style={{ overflowWrap: 'anywhere', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {ticket.isQrCode 
                ? 'Open QR Code'
                : 'Open URL'
            }
          </a>
        </>
      }
      </div>
    </div>
  );
};
