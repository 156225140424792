import React, { useEffect } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKETS_LIST_FOR_TICKETS_MODAL } from "../../queries/vip-workflow";
import PackageDetailsCard from "../../components/StageThreeCards/PackageDetailsCard";
import { EDITABLE_CART_TYPES, SERVICE_STATE, STAGE_ONE_CARDS } from "../../utils/constants";
import HotelGroupCardNew from "../../components/StageOneCards/HotelGroupCardNew";
import AirportTransferGroupCardNew from "../../components/StageOneCards/AirportTransferGroupCardNew";
import CityPassGroupCardNew from "../../components/StageOneCards/CityPassGroupCardNew";
import VegasLimoTransferGroupCardNew from "../../components/StageOneCards/VegasLimoTransferGroupCardNew";
import LimoGroupCardNew from "../../components/StageOneCards/LimoGroupCardNew";
import DinnerGroupCardNew from "../../components/StageOneCards/DinnerGroupCardNew";
import AirportTransferArrivalGroupCardNew from "../../components/StageOneCards/AirportTransferArrivalGroupCardNew";
import AirportTransferDepartureGroupCardNew from "../../components/StageOneCards/AirportTransferDepartureGroupCardNew";
import VegasLimoTransferArrivalGroupCardNew from "../../components/StageOneCards/VegasLimoTransferArrivalGroupCardNew";
import VegasLimoTransferDepartureGroupCardNew from "../../components/StageOneCards/VegasLimoTransferDepartureGroupCardNew";

import "./cartsContainer.scss";

function getStageOneCardLayout(
  {
    cart,
    cartId,
    datetime,
    time,
    isPublished,
    isServiceDeclined,
    packageDetailsId,
    cartGroup,
    cardDescription,
    setPackageOrderWithDetails,
    attendeeQty,
    decryptedInvoiceId,
    missingInfoCards,
    setMissingInfoCards,
    parsedCardData,
    config,
    packageOrderWithDetails,
    cardsWithTickets,
    ticketsForCard,
  }: any,
) {
  const isMissingInfo = !isPublished && !cartId;

  const STAGE_ONE_CARDS = {
    "Airport Transfer": (
      <>
        <AirportTransferGroupCardNew
          isMissingInfo={isMissingInfo}
          isPublished={isPublished}
          isServiceDeclined={isServiceDeclined}
          serviceDeclined={cart.cartDetails?.isServiceDeclined}
          packageDetailsId={packageDetailsId}
          setPackageOrderWithDetails={setPackageOrderWithDetails}
          decryptedInvoiceId={decryptedInvoiceId}
          setMissingInfoCards={setMissingInfoCards}
          cart={cart}
          datetime={datetime}
          time={time}
          cardDescription={cardDescription}
          cardGroup={cartGroup}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
        />
      </>
    ),
    "Airport Transfer Arrival": (
      <>
        <AirportTransferArrivalGroupCardNew
          isMissingInfo={isMissingInfo}
          isPublished={isPublished}
          isServiceDeclined={isServiceDeclined}
          serviceDeclined={cart.cartDetails?.isServiceDeclined}
          packageDetailsId={packageDetailsId}
          setPackageOrderWithDetails={setPackageOrderWithDetails}
          decryptedInvoiceId={decryptedInvoiceId}
          setMissingInfoCards={setMissingInfoCards}
          cart={cart}
          datetime={datetime}
          time={time}
          cardDescription={cardDescription}
          cardGroup={cartGroup}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
        />
      </>
    ),
    "Airport Transfer Departure": (
      <>
        <AirportTransferDepartureGroupCardNew
          isMissingInfo={isMissingInfo}
          isPublished={isPublished}
          isServiceDeclined={isServiceDeclined}
          serviceDeclined={cart.cartDetails?.isServiceDeclined}
          packageDetailsId={packageDetailsId}
          setPackageOrderWithDetails={setPackageOrderWithDetails}
          decryptedInvoiceId={decryptedInvoiceId}
          setMissingInfoCards={setMissingInfoCards}
          cart={cart}
          datetime={datetime}
          time={time}
          cardDescription={cardDescription}
          cardGroup={cartGroup}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
        />
      </>
    ),
    Limo: (
      <LimoGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        time={time}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    "City Pass": (
      <CityPassGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        attendeeQty={attendeeQty}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    Dinner: (
      <DinnerGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        time={time}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        packageOrderWithDetails={packageOrderWithDetails}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    "Vegas Limo Transfer": (
      <VegasLimoTransferGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        time={time}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    "Vegas Limo Transfer Arrival": (
      <VegasLimoTransferArrivalGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        time={time}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    "Vegas Limo Transfer Departure": (
      <VegasLimoTransferDepartureGroupCardNew
        isMissingInfo={isMissingInfo}
        isPublished={isPublished}
        isServiceDeclined={isServiceDeclined}
        serviceDeclined={cart.cartDetails?.isServiceDeclined}
        packageDetailsId={packageDetailsId}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        decryptedInvoiceId={decryptedInvoiceId}
        setMissingInfoCards={setMissingInfoCards}
        cart={cart}
        datetime={datetime}
        time={time}
        cardDescription={cardDescription}
        cardGroup={cartGroup}
        cardsWithTickets={cardsWithTickets}
        ticketsForCard={ticketsForCard}
      />
    ),
    Hotel: (
      <>
        <HotelGroupCardNew
          isMissingInfo={isMissingInfo}
          isPublished={isPublished}
          isServiceDeclined={isServiceDeclined}
          serviceDeclined={cart.cartDetails?.isServiceDeclined}
          packageDetailsId={packageDetailsId}
          setPackageOrderWithDetails={setPackageOrderWithDetails}
          decryptedInvoiceId={decryptedInvoiceId}
          setMissingInfoCards={setMissingInfoCards}
          cart={cart}
          datetime={datetime}
          time={time}
          cardDescription={cardDescription}
          cardGroup={cartGroup}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
        />
      </>
    ),
  };
  return STAGE_ONE_CARDS[cartGroup as keyof typeof STAGE_ONE_CARDS] || null;
}

const CartsContainer = ({
  packageOrderWithDetails,
  setModalType,
  setPackageDetailsId,
  setIsModalOpened,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  isCartInfoOpenedId,
  handleOpenDetails,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isInvoiceArchived,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  missingInfoCards,
  setMissingInfoCards,
  isPublished,
  parsedCardData,
  config,
}: any) => {
  const [getTickets, { data: ticketsData, loading: ticketsLoading }] = useLazyQuery(GET_TICKETS_LIST_FOR_TICKETS_MODAL, {
    fetchPolicy: "network-only",
  });
  
  const ticketsList = ticketsData?.getTicketsListForTicketsModalForClient;
  
  const publishedCards = packageOrderWithDetails.orderDetails.filter((orderDetail: any) => orderDetail.cartDetails.isPublished);
  const cardsWithTickets = publishedCards.filter((orderDetail: any) => {
    if (!ticketsList) return false;

    const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));

    const ticketsForCard = ticketEntries.find(([key, value]: any) =>
      value.some((ticket: any) => ticket.packageDetailsId === orderDetail.cartDetails.packageDetailsId &&
      (ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink))
    );

    return ticketsForCard ? true : false;
  });
  
  const ticketEntries = Object.entries(JSON.parse(ticketsList || "{}"));
  
  useEffect(() => {
    const invoiceId = Number(decryptedInvoiceId);
    if (invoiceId) {
      getTickets({ variables: { invoiceId } });
    }
  }, [decryptedInvoiceId, getTickets]);
  
  return packageOrderWithDetails.orderDetails.map(
    (cart: any, cartId: number, orderDetailsArray: any) => {
      const [cartGroup, cartTickets]: any = ticketEntries.find(([key, value]: any) => {
        return value.some((ticket: any) => 
          cart.packageDetailsId === ticket.packageDetailsId
        );
      }) || ['', []];
      
      const ticketsForCard = cartTickets?.length ? cartTickets : [];
      
      const attendeeQty = packageOrderWithDetails.qty;
      const parsedCardData: any = cart.cartDetails && JSON.parse(cart.cartDetails.cartJsonData);

      const prevParsedCartData: any =
        cartId !== 0 && orderDetailsArray[cartId - 1].cartDetails
          ? JSON.parse(orderDetailsArray[cartId - 1].cartDetails.cartJsonData)
          : null;

      let curDate = parsedCardData?.date ? parsedCardData.date : null;
      let prevDate = prevParsedCartData?.date ? prevParsedCartData.date : null;

      if (cart.group === "Airport Transfer") {
        curDate =
          parsedCardData?.transfer1?.date1 || parsedCardData
            ? parsedCardData?.transfer1?.date1 || parsedCardData?.date1 || parsedCardData?.date2
            : null;
      }
      
      if (cart.group === "Airport Transfer Arrival" || cart.group === "Airport Transfer Departure") {
        curDate = parsedCardData && parsedCardData?.date ? parsedCardData?.date : null;
      }

      if (
        cartId !== 0 &&
        orderDetailsArray[cartId - 1] &&
        orderDetailsArray[cartId - 1].group === "Airport Transfer"
      ) {
        prevDate =
          prevParsedCartData?.transfer1 || prevParsedCartData
            ? prevParsedCartData?.transfer1?.date1 ||
              prevParsedCartData?.date1 ||
              prevParsedCartData?.date2
            : null;
      }
      
      if (cart.group === "Vegas Limo Transfer") {
        curDate =
          parsedCardData?.transfer1?.pickUp1 || parsedCardData
            ? parsedCardData?.transfer1?.pickUp1 || parsedCardData?.pickUp1 || parsedCardData?.pickUp1
            : null;
      }
      
      if (cart.group === "Vegas Limo Transfer Arrival" || cart.group === "Vegas Limo Transfer Departure") {
        curDate = parsedCardData && parsedCardData?.pickUp ? parsedCardData?.pickUp : null;
      }

      if (
        cartId !== 0 &&
        orderDetailsArray[cartId - 1] &&
        orderDetailsArray[cartId - 1].group === "Vegas Limo Transfer"
      ) {
        prevDate =
          prevParsedCartData?.transfer1 || prevParsedCartData
            ? prevParsedCartData?.transfer1?.pickUp1 ||
              prevParsedCartData?.pickUp1 ||
              prevParsedCartData?.pickUp2
            : null;
      }
      
      if (
        cartId !== 0 &&
        orderDetailsArray[cartId - 1] &&
        ((orderDetailsArray[cartId - 1].group === 'Airport Transfer Arrival') ||
        (orderDetailsArray[cartId - 1].group === 'Airport Transfer Departure') ||
        (orderDetailsArray[cartId - 1].group === 'Vegas Limo Transfer Arrival') ||
        (orderDetailsArray[cartId - 1].group === 'Vegas Limo Transfer Departure'))
      ) {
        prevDate =
          prevParsedCartData
            ? prevParsedCartData?.date ||
              prevParsedCartData?.pickUp
            : null;
      }

      const IS_SHOW_MISSING_INFO_LABEL =
        cart.type &&
        EDITABLE_CART_TYPES.includes(cart.type) &&
        cart.cartDetails &&
        !isInvoiceArchived &&
        cart.isCardHasMissingInfo;

      const CARD_TITLE = cart.cartDetails?.cardTitle ? cart.cartDetails?.cardTitle : cart.type;

      const IS_SHOW_DECLINE_SERVICE_BUTTON =
        !cart.cartDetails?.isServiceDeclined &&
        !(
          cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED ||
          cart.cartDetails?.isServiceDeclined === SERVICE_STATE.UNDECLINED
        ) &&
        !STAGE_ONE_CARDS.includes(cart.group) &&
        !cart.cartDetails?.cartJsonData &&
        CARD_TITLE !== "Event Details";
        
      const IS_DECLINE_SERVICE = cart.cartDetails?.isServiceDeclined === SERVICE_STATE.DECLINED || cart.cartDetails?.isServiceDeclined === SERVICE_STATE.UNDECLINED;
      
      const time = curDate && moment(curDate).format('LT');

      return (
        <React.Fragment key={`carts-container-${cartId}`}>
          {STAGE_ONE_CARDS.includes(cart.group) ? 
            (getStageOneCardLayout({
              cart: cart,
              datetime: curDate && (moment(curDate).format('l') !== moment(prevDate).format('l')) ? curDate : null,
              time: time,
              cartId: cart.cartDetails.cartId,
              isPublished: cart?.cartDetails?.isPublished,
              isServiceDeclined: IS_DECLINE_SERVICE,
              // isServiceDeclined: cart?.cartDetails?.isServiceDeclined === 'UNDECLINED' ? false : true,
              packageDetailsId: cart.packageDetailsId,
              cartGroup: cart.group,
              cardDescription: cart?.cartDetails?.cardDescription,
              setPackageOrderWithDetails: setPackageOrderWithDetails,
              attendeeQty: attendeeQty,
              decryptedInvoiceId: decryptedInvoiceId,
              missingInfoCards: missingInfoCards,
              setMissingInfoCards: setMissingInfoCards,
              config: config,
              packageOrderWithDetails: packageOrderWithDetails,
              cardsWithTickets: cardsWithTickets,
              ticketsForCard: ticketsForCard,
            })) : 
            (
              <div className="outer-card-container">
                <PackageDetailsCard
                  // key={cartId}
                  cardDate={curDate && (moment(curDate).format('l') !== moment(prevDate).format('l')) ? curDate : null}
                  cardTime={time}
                  isShowMissingInfoLabel={IS_SHOW_MISSING_INFO_LABEL}
                  isShowDeclineServiceButton={IS_SHOW_DECLINE_SERVICE_BUTTON}
                  serviceDeclined={cart.cartDetails?.isServiceDeclined}
                  cart={cart}
                  cardTitle={CARD_TITLE}
                  setModalType={setModalType}
                  setPackageDetailsId={setPackageDetailsId}
                  setIsModalOpened={setIsModalOpened}
                  airportTransferType={airportTransferType}
                  setAirportTransferType={setAirportTransferType}
                  vegasLimoTransferType={vegasLimoTransferType}
                  setVegasLimoTransferType={setVegasLimoTransferType}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  handleOpenLearnMore={handleOpenLearnMore}
                  isLearnMoreOpenedId={isLearnMoreOpenedId}
                  isInvoiceArchived={isInvoiceArchived}
                  setPackageOrderWithDetails={setPackageOrderWithDetails}
                  parsedCardData={parsedCardData}
                  isMissingInfo={false}
                  isPublished={cart?.cartDetails?.isPublished}
                  isServiceDeclined={cart?.cartDetails?.isServiceDeclined === 'UNDECLINED' ? false : true}
                  packageDetailsId={cart.packageDetailsId}
                  decryptedInvoiceId={decryptedInvoiceId}
                  setMissingInfoCards={setMissingInfoCards}
                  cardDescription={cart?.cartDetails?.cardDescription}
                  cardGroup={cart.group}
                  cardsWithTickets={cardsWithTickets}
                  ticketsForCard={ticketsForCard}
                />
              </div>
            )
          }
        </React.Fragment>
      );
    },
  );
};

export default CartsContainer;
