import { useApolloClient } from "@apollo/client";
import "./editPackageDetailsModal.scss";
import { pdfjs } from "react-pdf";
import { base64ToBlob } from "../../utils/services";
import { GET_FILE_BASE64, GET_FILE_DOWNLOAD_LINK } from "../../queries/vip-workflow";
import downloadIcon from "../../assets/img/icons/cards/download-icon.svg";
import qrCodeIcon from "../../assets/img/icons/cards/qr-code-icon.svg";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadedTickets = ({
  ticket,
  index,
  setFile,
}: any) => {
  const client = useApolloClient();

  const handleTicketClick = async () => {
    const newWindow = window.open('', '_blank');

    const { data } = await client.query({
      query: GET_FILE_DOWNLOAD_LINK,
      variables: { id: ticket.id },
      fetchPolicy: "no-cache",
    });

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any)?.MSStream;
    if (isIOS) {
      try {
        const { data: base64Data } = await client.query({
          query: GET_FILE_BASE64,
          variables: { id: ticket.id },
          fetchPolicy: "no-cache",
        });

        if (base64Data?.getFile) {
          const matches = base64Data.getFile.match(/^data:(.*);base64,/);
          const mimeType = matches ? matches[1] : 'application/octet-stream';
          const base64Content = base64Data.getFile.split(',')[1];
          const blob = base64ToBlob(base64Content, mimeType);
          const blobUrl = URL.createObjectURL(blob);

          if (newWindow) {
            newWindow.location.href = blobUrl;
          }

          setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
        } else {
          if (newWindow) {
            newWindow.close();
          }
          console.warn("No file data available for download.");
        }
      } catch (error) {
        console.error("Error fetching or processing the file:", error);
        if (newWindow) {
          newWindow.close();
        }
      }
    } else {
      if (data?.getFileDownloadLink) {
        const link = document.createElement("a");
        link.href = data.getFileDownloadLink;
        link.download = ticket.key;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn("No download link available.");
        if (newWindow) {
          newWindow.close();
        }
      }
    }
  };

  const handlePreviewClick = async () => {
    try {
      const { data } = await client.query({
        query: GET_FILE_BASE64,
        variables: { id: ticket.id },
        fetchPolicy: "no-cache",
      });

      if (data?.getFile) {
        setFile(data.getFile);
      } else {
        console.warn("No file data available for preview.");
      }
    } catch (error) {
      console.error("Error fetching the file for preview:", error);
    }
  };

  return (
    <div
      className="d-flex justify-content-between"
      key={index}
    >
      <div className="d-flex align-items-center" style={{ width: "100%" }}>
        <button 
          type="button"
          style={{ cursor: 'pointer' }}
          className="btn btn-link p-0"
          onClick={handleTicketClick}
        >
          {ticket.isQrCode
            ? <img src={qrCodeIcon} alt="qr-icon" style={{ cursor: 'pointer' }}/>
            : <img src={downloadIcon} alt="download-icon" style={{ cursor: 'pointer' }}/>
          } 
        </button>

        <button
          type="button"
          className="btn btn-link p-2"
          style={{ overflowWrap: 'anywhere', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          onClick={handlePreviewClick}
        >
          {ticket.isQrCode 
            ? <>Open QR Code</>
            : <>View Tickets</>
          }
        </button>
      </div>
    </div>
  );
};

export default UploadedTickets;
