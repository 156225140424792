import { gql } from "@apollo/client";

export const GET_PACKAGE_WITH_DETAILS_BY_INVOICE_ID = gql`
  query getOnePackageWithDetailsForClient($invoiceId: Float!, $orderDetailsFilter: String) {
    getOnePackageWithDetailsForClient(
      invoiceId: $invoiceId
      orderDetailsFilter: $orderDetailsFilter
    ) {
      invoiceId
      eventName
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      section
      row
      qty
      recipient
      recipientEmail
      isTicketOneFromOneTicketType
      isHasTicketsExceptEvent
      isHasAcceptanceUrl
      isHasIdInUrlField
      isHasPdfEventTicket
      isTicketsSent
      externalPo
      ticketRequestId
      orderId
      client
      alternativeName
      orderDetails {
        description
        type
        packageDetailsId
        isCardHasMissingInfo
        isCardHasPendingInfo
        group
        cartDetails {
          cartId
          packageDetailsId
          isServiceDeclined
          cartJsonData
          packageOrderId
          isPublished
          cardTitle
          cardDescription
        }
      }
    }
  }
`;

export const GET_FULL_PACKAGE_WITH_DETAILS_BY_INVOICE_ID = gql`
  query getOneFullPackageWithDetails($invoiceId: Float!, $orderDetailsFilter: String) {
    getOneFullPackageWithDetails(invoiceId: $invoiceId, orderDetailsFilter: $orderDetailsFilter) {
      invoiceId
      eventName
      qty
      saleDate
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      shipBy
      ticketGroupCode
      section
      row
      trackingNumber
      recipient
      alternativeName
      recipientEmail
      externalPo
      purchaseOrderId
      orderDetails {
        budget
        po
        description
        delivered
        csr
        type
        cost
        packageDetailsId
        group
        groupId
        confirmationNumber
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          packageOrderId
          isPublished
          cardTitle
          isServiceDeclined
        }
      }
    }
  }
`;

export const GET_PACKAGES_FOR_CLIENT = gql`
  query getClientPackages($email: String!, $showOrders: String!, $eventNameSearch: String) {
    getClientPackages(email: $email, showOrders: $showOrders, eventNameSearch: $eventNameSearch) {
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      section
      row
      trackingNumber
      invoiceId
      eventName
      startDate
      endDate
      eventDate
      startsIn
      isCardHasMissingInfo
      isCardHasPendingInfo
      shipBy
      isTicketsSent
    }
  }
`;

export const UPDATE_CART_DETAILS = gql`
  mutation updateOrCreateCartDetails($editCartDetailsInput: EditCartDetailsInput!) {
    editCartDetails(editCartDetailsInput: $editCartDetailsInput) {
      invoiceId
      eventName
      venue
      venueCity
      venueAddress
      venuePostalCode
      venueStateId
      stateName
      startDate
      endDate
      eventDate
      section
      row
      orderDetails {
        description
        type
        packageDetailsId
        isCardHasMissingInfo
        isCardHasPendingInfo
        group
        cartDetails {
          cartId
          packageDetailsId
          cartJsonData
          packageOrderId
          isPublished
          cardTitle
        }
      }
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query getCartDetailsByPackageDetailsId($packageDetailsId: Float!) {
    getCartDetailsByPackageDetailsId(packageDetailsId: $packageDetailsId) {
      cartId
      packageDetailsId
      cartJsonData
      packageOrderId
      cardTitle
    }
  }
`;

export const GET_PACKAGE_CURRENT_TAB = gql`
  query packageCurrentTab($invoiceId: Float!) {
    packageCurrentTab(invoiceId: $invoiceId) {
      currentTab
      invoiceId
    }
  }
`;

export const GET_PACKAGE_CURRENT_TAB_BY_PO = gql`
  query packageCurrentTabByPo($po: Float!) {
    packageCurrentTabByPo(po: $po) {
      currentTab
      po
    }
  }
`;

export const GET_FILE_DOWNLOAD_LINK = gql`
  query getFileDownloadLink($id: Float!) {
    getFileDownloadLink(id: $id)
  }
`;

export const GET_FILE_BASE64 = gql`
  query getFile($id: Float!) {
    getFile(id: $id)
  }
`;

export const GET_BROKER_DASHBOARD_DATA = gql`
  query getBrokerDashboardPackages(
    $brokerId: Float!
    $showOrders: String!
    $page: Float!
    $itemsPerPage: Float!
    $sortBy: String
    $sortDirection: String
    $filter: FilterInput
    $sortDateRangeBy: String
    $dateRange: [String!]
  ) {
    getBrokerDashboardPackages(
      brokerId: $brokerId
      showOrders: $showOrders
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
      sortDateRangeBy: $sortDateRangeBy
      dateRange: $dateRange
    ) {
      orders {
        invoiceId
        externalPo
        saleDate
        client
        recipient {
          recipientName
        }
        eventName
        venue
        eventDate
        qty
        shipBy
      }
      totalPageCount
      totalPackagesCount
    }
  }
`;

export const SAVE_STAGE_ONE_CARD = gql`
  mutation saveStageOneCard($editCartDetailsInput: EditCartDetailsInput!) {
    saveStageOneCard(editCartDetailsInput: $editCartDetailsInput) {
      cartId
      packageDetailsId
      cartJsonData
      cardTitle
      isPublished
    }
  }
`;

export const DECLINE_SERVICE = gql`
  mutation declineService($packageDetailsId: Float!) {
    declineService(packageDetailsId: $packageDetailsId)
  }
`;

export const GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO = gql`
  query getAvailableFiltersForPackageInfo($invoiceId: Float!) {
    getAvailableFiltersForPackageInfo(invoiceId: $invoiceId) {
      filtersList
      showMissingInfoFor
    }
  }
`;

export const GET_TICKETS_LIST_FOR_TICKETS_MODAL = gql`
  query getTicketsListForTicketsModalForClient($invoiceId: Float!) {
    getTicketsListForTicketsModalForClient(invoiceId: $invoiceId)
  }
`;

export const CREATE_SUPPORT_TICKET = gql`
  mutation supportTicket($inputGetInTouch: GetInTouchDTO!) {
    supportTicket(inputGetInTouch: $inputGetInTouch)
  }
`;